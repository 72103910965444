import React, { FunctionComponent /*, useEffect*/ } from "react"
import { PageProps } from "../../types/page"
import styles from "./cookies.module.scss"
import { Page } from "@components/page/page"
import { defaultMetadata } from "@core/constants"

export const CookiesPage: FunctionComponent<PageProps> = (props) => {
  return (
    <Page
      {...props}
      metadata={{
        ...defaultMetadata,
        title: `Ligonier Ministries Cookie Policy`
      }}
    >
      <div className={styles.wrapper}>
        <h1 className={styles.header}>Cookie Policy</h1>

        <div className={styles.content}>
          <p>
            A cookie is a small piece of data (text file) that a website - when
            visited by a user - asks your browser to store on your device in
            order to remember information about you, such as your language
            preference or login information. Those cookies are set by us and
            called first party cookies. We also use third party cookies - which
            are cookies from a domain different than the domain of the website
            you are visiting - for our advertising and marketing efforts.
          </p>
          <p>
            More specifically, we use cookies and other tracking technologies
            for the following purposes:
          </p>
          <ul>
            <li>Assisting you in navigation;</li>
            <li>
              Assisting in registration to our events, login, and your ability
              to provide feedback;
            </li>
            <li>
              Analyzing your use of our products, services or applications;
            </li>
            <li>
              Assisting with our promotional and marketing efforts (including
              behavioral advertising).
            </li>
          </ul>
          <p>
            Below is a detailed list of the cookies we use on our Website. Our
            Website is scanned with our cookie scanning tool regularly to
            maintain a list as accurate as possible. We classify cookies in the
            following categories:
          </p>
          <ul>
            <li>Strictly Necessary Cookies</li>
            <li>Performance Cookies</li>
            <li>Functional Cookies</li>
            <li>Presentation Cookies</li>
            <li>Social Media Cookies</li>
          </ul>
          <p>
            You can opt-out of each cookie category (except strictly necessary
            cookies) by clicking on the &quot;Cookie Settings&quot; button
            below:
          </p>
          <div className={styles.cookieButton}>
            <a className="optanon-show-settings">Cookie Settings</a>
          </div>
          <div id="optanon-cookie-policy"></div>
        </div>
      </div>
    </Page>
  )
}

export default CookiesPage
